<template>
  <div class="h-100 d-flex justify-content-around align-items-center flex-wrap">
    <div  v-for="(item, index) in allItems" :key="index" class="w-25 d-flex justify-content-center">
      <Item :item="item" force-tooltip />
    </div>

    <span v-tooltip="{shown: true, content: 'Test content'}">test</span>

    <UserProfile :profile="'guest#668053'" :shown="true"></UserProfile>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Item from "@/components/items/Item.vue";
import UserProfile from "@/components/chat/UserProfile.vue";

export default {
  name: "DebugPage",
  components: {UserProfile, Item},
  computed: {
    ...mapGetters({
      inventory: "user/inventory"
    }),
    allItems() {
      return [
        // this.weapon,
        // this.tool,
        // this.tool2,
        // this.armor,
        this.food,

      ]
    },
    weapon() {
      return this.inventory.filter(s => s.Item.name === 'Diamond Sword');
    },
    tool() {
      return this.inventory.find(s => s.Item.name === 'Emerald Fishing Rod');
    },
    tool2() {
      return this.inventory.find(s => s.Item.name === 'Iron Axe');
    },
    food() {
      return this.inventory.find(s => s.Item.name === 'Cooked Cod');
    },
    armor() {
      return this.inventory.find(s => s.Item.name === 'Diamond Chestplate');
    }
  },

}
</script>